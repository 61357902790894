const SERVICE_KEY = 'service_0vs4pmo';
const TEMPLATE_KEY = 'template_r5x6iag';
const FORM_KEY = 'cLQasyeWZPPPe4Gbu';

const API_KEYS = {
	API1: SERVICE_KEY,
	API2: TEMPLATE_KEY,
	API3: FORM_KEY
};

export default API_KEYS;
